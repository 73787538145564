<template>
  <div id="page-user-view">
    <div class="vx-row">
      <div class="vx-col">
        <div class="page-title p-2 mb-6">
          <div class="vx-col w-full flex items-center data-list-btn-container">
            <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="relative vx-row m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
              v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
              <div class="text-sm">{{$t(tab)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeTab == 0" class="vx-col flex-1" id="account-info-col-1">
      <shipblu-card :cardLoading="dataLoading" class="mb-base p-5">
        <p class="h4 mb-6">{{$t('Merchant Pricing')}}</p>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <label class="mt-10 font-semibold">{{$t('Pricing Package')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <template  v-if="!editable">
              <a :href="data.pricing_lookup_delivery.package.google_sheet_url" v-if="!editable" target="blank">{{data.pricing_lookup_delivery.package.name}}</a>
            </template>
            <v-select v-else autocomplete="nofill" class="select-large w-full" name="pricing package" v-model="data.pricing_lookup_delivery.package"
              v-validate="'required'" :placeholder="$t('Pricing Package')" label="name" :options="pricingPackages"/>
            <span class="text-danger text-sm" v-show="errors.has('pricing package')">{{ errors.first('pricing package') }}</span>
          </div>
        </div>
        <div v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'commercial-admin'" class="flex flex-wrap items-center justify-end mt-6">
          <vs-button class="ml-auto mt-2" v-if="!editable" @click="editable = true">{{$t('Edit')}}</vs-button>
          <vs-button class="ml-auto mt-2" v-if="editable" @click="updateMerchant()">{{$t('Save')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="editable" @click="cancelEdit()" type="border" color="warning">{{$t('Cancel')}}</vs-button>
        </div>
      </shipblu-card>
      <merchant-cash-fees/>
    </div>
    <merchant-pricing-fulfillment v-else/>
  </div>
</template>

<script>
import {sendFulfillmentRequest, sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import MerchantCashFees from './MerchantCashFees.vue'
import MerchantPricingFulfillment from './MerchantPricingFulfillment.vue'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      tabs: ['Delivery Pricing', 'Fulfillment Pricing'],
      tabsDic: {'0': 'Delivery Pricing', '1': 'Fulfillement Pricing'},
      tabsNameDic: {'Delivery Pricing': 0, 'Fulfillement Pricing': 1},
      activeTab: 0,
      editable: false,
      pricingPackages: [],
      data: {
        pricing_lookup_delivery: {
          package: {},
          status: ''
        }
      },
      dataLoading: false,
      merchantFulfillment: false
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
    },
    activeTab () {
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab]
        }
      }).catch(() => {})
    }
  },
  methods: {
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    cancelEdit () {
      this.editable = !this.editable
      this.loadPricingPackages()
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadPricingPackages () {
      sendRequest(false, false, this, 'api/v1/pricing-packages/?limit=100', 'get', null, true,
        (response) => {
          this.pricingPackages = []
          response.data.results.forEach(element => {
            if (element.status === 'ready') {
              this.pricingPackages.push(element)
            }
          })
        }
      )
    },
    loadMerchant () {
      this.dataLoading = true
      sendRequest(true, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'get', null, true,
        (response) => {
          this.data = response.data
          this.dataLoading = false
        }
      )
    },
    updateMerchant () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const pricingObj = {
            pricing_package: this.data.pricing_lookup_delivery.package.id
          }
          sendRequest(false, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'patch', pricingObj, true,
            () => {
              this.editable = false
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Merchant'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.loadMerchant()
            }
          )
        }
      })
    },
    loadMerchantFulfillment () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/merchants/${this.$route.params.merchantID}/`, 'get', null, true,
        () => {
          this.merchantSubscribed = true
        }, (error) => {
          if (error.response.status === 404 || error.response.status === 403) {
            this.tabs.pop()
          }
        })
    }
  },
  components: {
    vSelect,
    MerchantCashFees,
    MerchantPricingFulfillment,
    ShipbluCard
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.loadPricingPackages()
    this.loadMerchant()
    this.loadMerchantFulfillment()
  }
}
</script>
